import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { mockDataContacts } from "../../data/mockData";
import { useEffect, useState } from "react";
// import fetchAPI from "../../api/fetch";
// import AppApiResources from "../../api/api-constent";
import axios from "axios";
import { toast } from "react-toastify";

const columns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  // { field: "registrarId", headerName: "Registrar ID" },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  // {
  //   field: "age",
  //   headerName: "Age",
  //   type: "number",
  //   headerAlign: "left",
  //   align: "left",
  // },
  {
    field: "gender",
    headerName: "Gender",
    flex: 1,
  },
  {
    field: "fathername",
    headerName: "Father Name",
    flex: 1,
  },
  {
    field: "age",
    headerName: "Age",
    flex: 1,
  },
  {
    field: "caste",
    headerName: "Caste",
    flex: 1,
  },
  {
    field: "district_name",
    headerName: "District",
    flex: 1,
  },
  {
    field: "panchayatname",
    headerName: "Panchayat===",
    flex: 1,
  },
  {
    field: "villagename",
    headerName: "Village",
    flex: 1,
  },
  {
    field: "dob",
    headerName: "Date Of Birth",
    flex: 1,
  },
  {
    field: "pollboth",
    headerName: "Poll Booth No.",
    flex: 1,
  },
  {
    field: "mobileno",
    headerName: "Phone Number",
    flex: 1,
  },
];

const Dashboard = () => {
  const [tableData, settableData] = useState([]);

  useEffect(() => {
    toast("Please Wait!! ", {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    let token = sessionStorage.getItem("accessToken");
    axios
      .get(
        "https://jansevak.drvedhealthcare.com/api/janalist/get?page=1&&limit=100",
        {
          headers: {
            Authorization: "Bearer " + token, //the token is a variable which holds the token
          },
        }
      )
      .then((res) => {
        setTimeout(() => {
          toast.dismiss();
        }, 500);
        // console.log("🚀 ~ file: index.js:82 ~ .then ~ res:", res);
        let data = res?.data?.data?.data?.rows;
        // console.log("🚀 ~ file: index.js:83 ~ .then ~ data:", data);
        if (data) {
          settableData(data);
          // console.log("🚀 ~ file: index.jsx:84 ~ JanaData ~ data:", data);
        }
      })
      .catch((e) => {
        console.log("🚀 ~ file: index.js:113 ~ useEffect ~ e:", e);
        toast.dismiss();
        toast.error(e.response?.data?.data.errors.toString(), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, []);

  // useEffect(() => {
  //   const res = fetchAPI.get(AppApiResources.janaList);
  // console.log("🚀 ~ file: index.js:77 ~ JanaData ~ res:", res);
  //   let data = res?.data?.data?.rows;
  //   if (data) {
  // console.log("🚀 ~ file: index.jsx:84 ~ JanaData ~ data:", data);
  //   }
  //   // w
  // });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      <Header title="HOME" subtitle="List of Information" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.orangeAccent[500],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.orangeAccent[500],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {tableData.length > 0 && (
          <DataGrid
            rows={tableData}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
