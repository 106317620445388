import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import CreateTeam from "./scenes/createteam";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import SignIn from "./scenes/login/login";
import Error from "./scenes/error/index";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const user = JSON.parse(sessionStorage.getItem("userData"));

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {!!user ? (
            <>
              <Sidebar isSidebar={isSidebar} />
              <main className="content">
                <Topbar setIsSidebar={setIsSidebar} />
                <Routes>
                  <Route path="/" element={<Navigate replace to="/home" />} />
                  <Route path="/home" element={<Dashboard />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/createteam" element={<CreateTeam />} />
                  <Route path="*" element={<Error />} />
                </Routes>
              </main>
            </>
          ) : (
            <Routes>
              <Route path="/" element={<Navigate replace to="/signin" />} />
              <Route path="/signin" element={<SignIn />} />
            </Routes>
          )}
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
