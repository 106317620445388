import { Box, Button, TextField } from "@mui/material";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateTeam = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [Token, setToken] = useState("");

  useEffect(() => {
    let token = sessionStorage.getItem("accessToken");
    setToken(token);
  });

  const handleFormSubmit = (values, { resetForm }) => {
    toast("Please Wait!! ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    let data = {
      name: values.firstName + " " + values.lastName,
      phone: values.contact,
      password: values.password,
    };
    // console.log("🚀 ~ file: index.jsx:25 ~ handleFormSubmit ~ data:", data);
    // console.log("🚀 ~ file: index.jsx:13 ~ CreateTeam ~ Token:", Token);
    axios
      .post("https://jansevak.drvedhealthcare.com/api/user/register", data, {
        headers: {
          Authorization: "Bearer " + Token, //the token is a variable which holds the token
        },
      })
      .then((res) => {
        // console.log("🚀 ~ file: index.js:82 ~ .then ~ res:", res);
        let data = res?.data?.data?.message;
        // console.log("🚀 ~ file: index.js:83 ~ .then ~ data:", data);
        if (data === "User created successfully!") {
          // console.log("🚀 ~ file: index.jsx:84 ~ JanaData ~ data:", data);
          // Formik.resetForm();
          // Formik.resetForm({
          //   values:initialValues
          // });
          resetForm();
          toast.dismiss();
          toast("User Created Successfully!! ", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((e) => {
        // console.log(
        //   "🚀 ~ file: index.jsx:52 ~ .then ~ e:",
        //   e.response?.data?.data.errors.toString()
        // );
        toast.dismiss();
        toast.error(e.response?.data?.data.errors.toString(), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <Box m="20px">
      <Header title="CREATE USER" subtitle="Create a New User Profile" />

      <Formik
        enableReinitialize
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Contact Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.contact}
                name="contact"
                error={!!touched.contact && !!errors.contact}
                helperText={touched.contact && errors.contact}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Confirm Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirmPassword}
                name="confirmPassword"
                error={!!touched.confirmPassword && !!errors.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create New User
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  // email: yup.string().email("invalid email").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  // password: yup.string().required("required"),
  // confirmPassword: yup.string().required("required"),
  password: yup
    .string()
    .required("Password is required")
    .test(
      "len",
      "Password must more than 6 characters",
      (val) => val.length >= 6
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
const initialValues = {
  firstName: "",
  lastName: "",
  // email: "",
  contact: "",
  password: "",
  confirmPassword: "",
};

export default CreateTeam;
