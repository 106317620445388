import fetch from ".";

export default {
  get: (url, params) => {
    return fetch({
      method: "GET",
      url: url,
      params: params,
    });
  },
  post: (url, body) => {
    return fetch({
      method: "POST",
      url: url,
      data: body,
    });
  },
  delete: (url, params) => {
    return fetch({
      method: "DELETE",
      url: url,
      params: params,
    });
  },
};
