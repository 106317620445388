import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useCallback } from "react";
import { DialogContentText } from "@mui/material";

const ConfirmBox = (props: EditCustomerDialogProps) => {
  const { open, closeHandler, userInfo, onSave } = props;

  const saveHandler = useCallback(function _saveHandler() {
    onSave({
      ...userInfo,
    });
    closeHandler();
  });

  return (
    <Dialog open={open} onClose={closeHandler}>
      <DialogTitle id="alert-dialog-title">{"User Access"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you want to change the Access!!
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={saveHandler}>{"Sure"}</Button>
        <Button onClick={closeHandler}>{"Cancel"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmBox;
