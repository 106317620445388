// import Chance from "chance";
import axios from "axios";
import { API_URL } from "./api-constent";
// const chance = Chance();

// export const fakeUserData = () => {
//     return `${chance.name()}, ${chance.country({ full: true })}`;
// }

let axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "content-type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    let token = JSON.parse(sessionStorage.getItem("accessToken"));
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response && response.status === 200) {
      if (
        response?.data &&
        response.data?.status === "success" &&
        response.data?.message
      ) {
        // NotificationManager.success(response.data.message, "Success", 3000);
      }
    }
    return response;
  },
  (err) => {
    const error = err.response;
    // if error is 401
    if (error.status === 401 && error.data && error.data.jwt_expired === 1) {
      sessionStorage.clear();
      window.location.reload();
      // if error is 401 for jwt_blacklisted
    } else if (
      error.status === 401 &&
      error.data &&
      error.data.jwt_blacklisted === 1
    ) {
      sessionStorage.clear();
      window.location.reload();
      // if error is 422
    } else if (error.status === 422 && error.data) {
      //   var value = null;
      //   for (var key in error.data?.errors) {
      //     if(!value){
      //       value = error.data.errors[key];
      //       NotificationManager.error(value[0], "Error", 5000);
      //     }
      //   }
      //   let singleError =
      //     error.data?.messages?.error?.msg ||
      //     error?.data?.message ||
      //     error?.data.messages;
      //   if (singleError && !value) {
      //     NotificationManager.error(singleError, "Error", 5000);
      //   }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
