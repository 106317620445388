import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Header from "../../components/Header";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";

import ConfirmBox from "../../components/ConfirmBox";

const Team = () => {
  // ------------------
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const closeDialogHandler = useCallback(function _handleClose() {
    setOpenEditDialog(false);
  }, []);

  const [selectedUser, setSelectedUser] = useState({
    userId: 0,
    userName: "",
  });

  const userEditHandler = (params) => {
    setSelectedUser({ ...params });
    setOpenEditDialog(true);
  };

  const saveHandler = useCallback(function _saveHandler(user) {
    console.log(
      // "🚀 ~ file: index.js:33 ~ saveHandler ~ user:",
      user.row.id,
      user.row.status
    );
    let status;
    if (user.row.status) {
      status = 0;
    } else {
      status = 1;
    }
    console.log({ user });
    let token = sessionStorage.getItem("accessToken");
    axios
      .put(
        `https://jansevak.drvedhealthcare.com/api/user/enable/account/${user.row.id}/${status}`,
        null,
        {
          headers: {
            Authorization: "Bearer " + token, //the token is a variable which holds the token
          },
        }
      )
      .then((res) => {
        // console.log("🚀 ~ file: index.js:47 ~ .then ~ res:", res);
        tableList();
      });
  });

  // ------------------

  const tableList = () => {
    let token = sessionStorage.getItem("accessToken");
    let data = {
      page: 1,
      limit: 100,
    };
    axios
      .post("https://jansevak.drvedhealthcare.com/api/user/get", data, {
        headers: {
          Authorization: "Bearer " + token, //the token is a variable which holds the token
        },
      })
      .then((res) => {
        // console.log("🚀 ~ file: index.js:82 ~ .then ~ res:", res);
        let data = res?.data?.data?.data?.rows;
        // console.log("🚀 ~ file: index.js:83 ~ .then ~ data:", data);
        if (data) {
          settableData(data);
          // console.log("🚀 ~ file: index.jsx:84 ~ JanaData ~ data:", data);
        }
      });
  };

  const [tableData, settableData] = useState([]);
  useEffect(() => {
    tableList();
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    // {
    //   field: "age",
    //   headerName: "Age",
    //   type: "number",
    //   headerAlign: "left",
    //   align: "left",
    // },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    // {
    //   field: "email",
    //   headerName: "Email",
    //   flex: 1,
    // },
    {
      field: "accessLevel",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { status } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              status === 1
                ? colors.greenAccent[600]
                : status === 0
                ? colors.redAccent[700]
                : colors.redAccent[700]
            }
            borderRadius="4px"
          >
            {status === 1 && <LockOpenOutlinedIcon />}
            {status === 0 && <LockOutlinedIcon />}
            <Typography
              color={colors.grey[100]}
              sx={{ ml: "5px" }}
            ></Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <ConfirmBox
        open={openEditDialog}
        closeHandler={closeDialogHandler}
        userInfo={selectedUser}
        onSave={saveHandler}
      />

      <Box m="20px">
        <Header title="TEAM" subtitle="Managing the Team Members" />
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.orangeAccent[500],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.orangeAccent[500],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGrid
            rows={tableData}
            columns={columns}
            onCellClick={userEditHandler}
          />
        </Box>
      </Box>
    </>
  );
};

export default Team;
